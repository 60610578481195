// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cds-index-js": () => import("./../../../src/pages/cds/index.js" /* webpackChunkName: "component---src-pages-cds-index-js" */),
  "component---src-pages-gallery-index-js": () => import("./../../../src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-templates-actors-and-artistic-song-js": () => import("./../../../src/templates/actors-and-artistic-song.js" /* webpackChunkName: "component---src-templates-actors-and-artistic-song-js" */),
  "component---src-templates-arrangements-js": () => import("./../../../src/templates/arrangements.js" /* webpackChunkName: "component---src-templates-arrangements-js" */),
  "component---src-templates-art-n-voices-js": () => import("./../../../src/templates/art-n-voices.js" /* webpackChunkName: "component---src-templates-art-n-voices-js" */),
  "component---src-templates-art-n-voices-live-in-provence-js": () => import("./../../../src/templates/art-n-voices-live-in-provence.js" /* webpackChunkName: "component---src-templates-art-n-voices-live-in-provence-js" */),
  "component---src-templates-benefis-dojrzalosci-js": () => import("./../../../src/templates/benefis-dojrzalosci.js" /* webpackChunkName: "component---src-templates-benefis-dojrzalosci-js" */),
  "component---src-templates-bio-page-js": () => import("./../../../src/templates/bio-page.js" /* webpackChunkName: "component---src-templates-bio-page-js" */),
  "component---src-templates-chamber-music-js": () => import("./../../../src/templates/chamber-music.js" /* webpackChunkName: "component---src-templates-chamber-music-js" */),
  "component---src-templates-choral-music-for-children-js": () => import("./../../../src/templates/choral-music-for-children.js" /* webpackChunkName: "component---src-templates-choral-music-for-children-js" */),
  "component---src-templates-choral-music-js": () => import("./../../../src/templates/choral-music.js" /* webpackChunkName: "component---src-templates-choral-music-js" */),
  "component---src-templates-classical-music-js": () => import("./../../../src/templates/classical-music.js" /* webpackChunkName: "component---src-templates-classical-music-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-droga-krzyzowa-z-chrystusem-na-kalwarii-wejherowskiej-js": () => import("./../../../src/templates/droga-krzyzowa-z-chrystusem-na-kalwarii-wejherowskiej.js" /* webpackChunkName: "component---src-templates-droga-krzyzowa-z-chrystusem-na-kalwarii-wejherowskiej-js" */),
  "component---src-templates-heaven-kiedy-niebo-zstepuje-na-ziemie-js": () => import("./../../../src/templates/heaven-kiedy-niebo-zstepuje-na-ziemie.js" /* webpackChunkName: "component---src-templates-heaven-kiedy-niebo-zstepuje-na-ziemie-js" */),
  "component---src-templates-koledy-europejskie-js": () => import("./../../../src/templates/koledy-europejskie.js" /* webpackChunkName: "component---src-templates-koledy-europejskie-js" */),
  "component---src-templates-midnight-stories-js": () => import("./../../../src/templates/midnight-stories.js" /* webpackChunkName: "component---src-templates-midnight-stories-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-piosenki-na-depresje-js": () => import("./../../../src/templates/piosenki-na-depresje.js" /* webpackChunkName: "component---src-templates-piosenki-na-depresje-js" */),
  "component---src-templates-solo-music-js": () => import("./../../../src/templates/solo-music.js" /* webpackChunkName: "component---src-templates-solo-music-js" */),
  "component---src-templates-spiewajmy-mlodzi-mlodym-js": () => import("./../../../src/templates/spiewajmy-mlodzi-mlodym.js" /* webpackChunkName: "component---src-templates-spiewajmy-mlodzi-mlodym-js" */),
  "component---src-templates-symphonic-and-orchestor-music-page-js": () => import("./../../../src/templates/symphonic-and-orchestor-music-page.js" /* webpackChunkName: "component---src-templates-symphonic-and-orchestor-music-page-js" */),
  "component---src-templates-tapering-js": () => import("./../../../src/templates/tapering.js" /* webpackChunkName: "component---src-templates-tapering-js" */),
  "component---src-templates-theater-music-and-song-js": () => import("./../../../src/templates/theater-music-and-song.js" /* webpackChunkName: "component---src-templates-theater-music-and-song-js" */),
  "component---src-templates-w-ceni-bozech-skrzidlow-js": () => import("./../../../src/templates/w-ceni-bozech-skrzidlow.js" /* webpackChunkName: "component---src-templates-w-ceni-bozech-skrzidlow-js" */),
  "component---src-templates-wsrod-ciszy-js": () => import("./../../../src/templates/wsrod-ciszy.js" /* webpackChunkName: "component---src-templates-wsrod-ciszy-js" */)
}

